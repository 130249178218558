<template>
  <a-modal
    title="入料單號"
    :visible="visible"
    width="800px"
    @cancel="handleCancel"
    @ok="handleOk"
  >
    <a-range-picker v-model:value="dateFromTo" style="margin-bottom: 12px;" @change="handleChangeDateFromTo" />
    <a-table :loading="loading" :columns="columns" :dataSource="list" rowKey="id" :pagination="false" :customRow="customRow"></a-table>
  </a-modal>
</template>

<script>
import moment from 'moment'

export default {
  name: 'material-list-table-modal',
  data() {
    return {
      dateFromTo: [moment(), moment()],
      list: [],
      loading: false,
      visible: false,
      columns: [
        {
          title: '入料單號',
          dataIndex: 'number',
          key: 'number',
        },
        {
          title: '物料來源',
          dataIndex: 'name',
          key: 'name',
        },
        {
          title: '物料數量',
          dataIndex: 'count',
          key: 'count',
        },
        {
          title: '屠體重量(公斤)',
          dataIndex: 'carcassWeight',
          key: 'carcassWeight',
        },
        {
          title: '毛雞重量(公斤)',
          dataIndex: 'livingWeight',
          key: 'livingWeight',
        },
      ],
      selectIndex: undefined
    }
  },
  computed: {
    customRow() {
      return (record, index) => {
        return {
          style: {
            'background-color': this.selectIndex === index ? '#e6f7ff' : undefined
          },
          on: {
            click: () => {
              this.selectIndex = index
            }
          }
        }
      }
    }
  },
  methods: {
    handleChangeDateFromTo(dates) {
      this.dateFromTo = dates
      this.getList()
    },
    getList() {
      this.loading = true
      this.$api.Inventory.getMaterialDepotOrderList({
        from: this.dateFromTo[0].format('YYYY-MM-DD'),
        to: this.dateFromTo[1].format('YYYY-MM-DD'),
      }).then(res => {
        this.list = res.data
        this.loading = false
      }).catch(() => {
        this.loading = true
      })
    },
    show() {
      this.visible = true
      this.selectIndex = undefined
      this.getList()
    },
    handleCancel() {
      this.visible = false
    },
    handleOk() {
      if (this.selectIndex != null) {
        const record = this.list[this.selectIndex]
        this.visible = false
        this.$emit('ok', record)
      }
    },
  },
}
</script>

<style scoped>

</style>

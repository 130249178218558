<template>
  <div class="container">
    <material-list-table-modal ref="materialListTableModal" @ok="handleSelectMaterial" />
    <add-material-form-modal ref="addMaterialFormModal" @ok="handleAddMaterial" />
    <div class="action">
      <div class="buttons">
        <a-space>
          <a-button class="reviewButton" @click="resetPage">重新整理</a-button>
          <a-button class="addButton1" @click="showAddPurchaseView"
            >重新入庫<a-icon type="plus"
          /></a-button>
        </a-space>
      </div>
      <div>
        <a-modal
          width="800px"
          v-model="purchaseViewVisible"
          title="重新入庫"
          @cancel="clearPurchaseViewData"
          class="addPurchaseView"
        >
          <a-form-model :model="list" ref="ruleForm" :rules="rules"
                        layout="inline"
                        :labelCol="{style: 'width: 112px; text-align: left;'}">
            <div class="purchase-form">
              <div class="purchase-form__row">
                <a-form-model-item
                  class="purchase-form__row__item"
                  label="工作日期"
                >
                  <div>{{ moment(new Date()).format('YYYY-MM-DD') }}</div>
                </a-form-model-item>
                <template v-if="purchaseViewProductCategories === 'RETAIL'">
                  <a-form-model-item
                    class="purchase-form__row__item"
                    label="入料單號:"
                    prop="orderId"
                  >
                    <div style="width: calc(100% - 72px); display: inline-block;"
                      @click="showMaterialListTableModal">
                      <a-input
                        placeholder="請選擇入料單號"
                        v-model="list.materialNumber"
                        style="width: 100%; background: #fff; cursor: pointer; color: black;"
                        disabled
                      />
                    </div>
                    <a-button type="primary" style="margin-left: 8px;" @click="showAddMaterialModal"><a-icon type="plus"/></a-button>
                  </a-form-model-item>
                  <a-form-model-item
                    class="purchase-form__row__item"
                    label="物料來源"
                  >
                    <div>{{ materialState.name }}</div>
                  </a-form-model-item>
                  <a-form-model-item
                    class="purchase-form__row__item"
                    label="物料數量"
                  >
                    <div>{{materialState.count}}</div>
                  </a-form-model-item>
                  <a-form-model-item
                    class="purchase-form__row__item"
                    label="屠體重量(公斤)"
                  >
                    <div>{{materialState.carcassWeight}}</div>
                  </a-form-model-item>
                  <a-form-model-item
                    class="purchase-form__row__item"
                    label="毛雞重量(公斤)"
                  >
                    <div>{{materialState.livingWeight}}</div>
                  </a-form-model-item>
                </template>
              </div>
              <div class="purchase-form__row">
                <a-form-model-item
                  class="purchase-form__row__item"
                  label="商品條碼"
                  prop="searchBarcode"
                >
                  <a-input
                    autoFocus
                    placeholder="請輸入商品條碼"
                    @change="getStock"
                    v-model="list.searchBarcode"
                    ref="inputDom"
                  />
                </a-form-model-item>
                <a-form-model-item
                  class="purchase-form__row__item"
                  label="儲存倉庫:"
                  prop="depotId"
                  v-if="purchaseViewProductCategories === 'RETAIL'"
                >
                  <a-select
                    placeholder="請選擇倉庫"
                    v-model="list.depotId"
                  >
                    <a-select-option v-for="e in purchaseViewDepotList" :value="e.id" :key="e.id">
                      {{ e.name }}
                    </a-select-option>
                  </a-select>
                </a-form-model-item>
                <a-form-model-item
                  class="purchase-form__row__item"
                  label="商品名稱:"
                >
                  <span>{{ inventoryList.productName }}</span>
                </a-form-model-item>
                <a-form-model-item
                  class="purchase-form__row__item"
                  label="計價單位:">
                  <span>{{ computedWeight(undefined, inventoryList.unit) }}</span>
                </a-form-model-item>
                <a-form-model-item
                  class="purchase-form__row__item"
                  label="數量:"
                  prop="amount"
                >
                  <div
                    v-if="['公斤','公克','台斤'].includes(inventoryList.unit)"
                  >
                  <span style="line-height: 35px;">{{
                      inventoryList.weight
                    }}</span>
                  </div>
                  <div v-else>
                    <a-input v-model="list.amount" />
                  </div>
                </a-form-model-item>
              </div>
            </div>
          </a-form-model>
          <template slot="footer">
            <a-button key="back" @click="onHidePurchaseModal">
              取消
            </a-button>
            <a-button key="submit" type="primary" @click="addInventory" :loading="submitLoading">
              儲存
            </a-button>
            <a-button type="primary" @click="submitNonStop" :loading="submitNonStopLoading">
              儲存並新增
            </a-button>
          </template>
        </a-modal>
      </div>
      <div class="search-wrapper">
        <div class="search-selection">
          <a-select
            v-model="depotName"
            style="width: 120px"
            @change="getDepotId"
          >
            <a-select-option value="">
              全部
            </a-select-option>
            <a-select-option v-for="item in this.goodsTable" :key="item.id">
              {{ item.name }}
            </a-select-option>
          </a-select>
        </div>
        <div class="searchInput">
          <a-input-search
            v-model="search"
            placeholder="搜尋商品名稱"
            enter-button
            @search="onSearch"
          />
        </div>
      </div>
    </div>
    <div class="itemMenu">
      <a-table
        class="inventoryTable"
        :columns="columns"
        bordered
        :pagination="false"
        :data-source="tableData"
        :rowKey="record => record.id"
        :expandedRowKeys="expandIndex"
      >
        <template class="expandRow" slot="expandedRowRender" slot-scope="item">
          <a-table
            v-if="item.inventoryList.length"
            class="innerTable"
            :data-source="item.inventoryList"
            :pagination="false"
            :showHeader="false"
            :rowKey="record => record.id"
            :columns="innerColumns"
          >
            <template slot="spaceCol">
              <span>{{ '' }}</span>
            </template>
            <template slot="unitAmount" slot-scope="text, record">
              <div
                v-if="['公斤','公克','台斤'].includes(item.unit)"
              >
                {{ parseFloat(record.weight.toFixed(3)) + ' ' + text }}
              </div>
              <div v-else>{{ '' }}</div>
            </template>
            <template slot="amount" slot-scope="text, record">
              <editable-cell
                :text="text + ''"
                @change="onInnerCellChange(record, $event)"
              />
            </template>
            <template slot="action" slot-scope="record">
              <a-popconfirm
                class="inventoryDeletePopconfirm"
                @confirm="() => onDelAmount(record)"
              >
                <template slot="title">
                  <span
                    class="inventoryDeletePopTitle"
                    style="font-size: larger;"
                    >商品刪除後，所有資料將清空無法還原</span
                  >
                </template>
                <a href="#">刪除</a>
              </a-popconfirm>
            </template>
          </a-table>
        </template>
        <template slot="expandAction" slot-scope="record">
          <div
            v-if="record.inventoryList.length"
            class="expandAction"
            @click="handleExpand(record.id)"
          >
            <a-icon
              :type="
                expandIndex.indexOf(record.id) != -1
                  ? 'minus-square'
                  : 'plus-square'
              "
            />
          </div>
          <span v-else></span>
        </template>
        <template slot="barCode" slot-scope="text, record">
          <div
            class="barCodeExpand"
            @click="handleExpand(record.id)"
            style="height: 20px; cursor: pointer;"
          >
            <span>{{ text !== undefined ? text : ' ' }}</span>
          </div>
        </template>
        <template slot="productName" slot-scope="text">
          <div>
            <span>{{ text }}</span>
          </div>
        </template>
        <template slot="amount" slot-scope="text, record">
          <editable-cell
            v-if="!record.inventoryList.length"
            :text="text + ''"
            @change="onCellChange(record.id, 'amount', $event)"
          />
          <span v-else>{{ text }}</span>
        </template>
        <template slot="action" slot-scope="record">
          <a-popconfirm
            v-if="!record.inventoryList.length"
            class="inventoryDeletePopconfirm"
            @confirm="() => onDelAmount(record)"
          >
            <template slot="title">
              <span class="inventoryDeletePopTitle" style="font-size: larger;"
                >商品刪除後，所有資料將清空無法還原</span
              >
            </template>
            <a href="#">刪除</a>
          </a-popconfirm>
          <span v-else>{{ '' }}</span>
        </template>
      </a-table>
    </div>
    <!--分頁-->
    <a-pagination
      class="pagination"
      v-model="current"
      :page-size-options="pageSizeOptions"
      :total="total"
      show-size-changer
      :page-size="pageSize"
      :show-total="total => ``"
      @showSizeChange="onShowSizeChange"
      @change="onPageChange"
    >
      <template slot="buildOptionText" slot-scope="props">
        <span>{{ props.value }}筆/頁</span>
        <!--        <span v-if="props.value === '50'">全部</span>-->
      </template>
    </a-pagination>
  </div>
</template>
<script>
import MaterialListTableModal from './components/material-list-table-modal'
import AddMaterialFormModal from './components/add-material-form-modal'
import moment from 'moment'
import EditableCell from '@/components/EditableCell'
import Fragment from '@/components/Fragment'
import { computedWeight } from '@/unit/dictionary/computed'
import formatPrice from '@/components/thousand'


function debounce(func, delay = 500) {
  let timer = null

  return () => {
    clearTimeout(timer)
    timer = setTimeout(() => {
      func()
    }, delay)
  }
}

export default {
  name: 'Inventory',
  components: {
    EditableCell,
    MaterialListTableModal,
    AddMaterialFormModal,
  },
  data() {
    return {
      submitLoading:false,
      submitNonStopLoading:false,
      selectList: [],
      specificId: '',
      barcodeTdWidth: '',
      customerList: [],
      goodsTable: [],
      inventoryList: [],
      barCodeSelection: [],
      purchaseViewVisible: false,
      purchaseViewProductCategories: null,
      purchaseViewDepotList: [],
      orderModalTitle: '出貨',
      list: { searchBarcode: '', amount: '', depotId: undefined, orderId: undefined, materialNumber: undefined },
      remark: '',
      barcode: '',
      expandIndex: [],
      tableData: [],
      search: '',
      columns: [
        {
          class: 'expand-td',
          dataIndex: '',
          align: 'center',
          scopedSlots: { customRender: 'expandAction' }
        },
        // {
        //   class: 'depot-td',
        //   title: '庫存倉庫',
        //   dataIndex: 'depotName',
        //   align: 'center',
        //   scopedSlots: { customRender: 'depotName' }
        // },
        // {
        //   class: 'barcode-td',
        //   title: '商品條碼',
        //   dataIndex: 'barCode',
        //   align: 'center',
        //   scopedSlots: { customRender: 'barCode' }
        // },
        {
          class: 'product-name-td',
          title: '商品名稱',
          dataIndex: 'productName',
          align: 'center',
          scopedSlots: { customRender: 'productName' }
        },
        {
          class: 'product-categories-td',
          title: '商品包裝',
          dataIndex: 'productCategories',
          align: 'center',
          scopedSlots: { customRender: 'productName' },
          customRender: (val) => {
            return {
              children: val === 'COMMERCIAL' ? '商用包' : '零售包'
            }
          }
        },
        {
          class: 'unit-td',
          title: '單位',
          dataIndex: 'unit',
          align: 'center',
          scopedSlots: { customRender: 'unit' }
        },
        {
          class: 'list-price-td',
          title: '建議售價',
          dataIndex: 'totalListPrice',
          align: 'center',
          customRender: (val, row) => {
            return {
              children: <div>${formatPrice(parseInt(row.price))}</div>
            }
          }
        },
        {
          class: 'amount-td',
          title: '包數',
          dataIndex: 'amount',
          align: 'center',
          scopedSlots: { customRender: 'amount' }
        },
        {
          class: 'weight-td',
          title: '總重量',
          dataIndex: 'totalWeight',
          align: 'center',
          customRender: (val, row) => {
            return {
              children: row.productCategories === 'COMMERCIAL' ? `${row.totalWeight.toFixed(3)}${row.unit}` : ''
            }
          }
        },
        {
          class: 'action-td',
          title: '操作',
          dataIndex: '',
          align: 'center',
          scopedSlots: { customRender: 'action' }
        }
      ],
      innerColumns: [
        {
          class: 'inner-expand-td',
          scopedSlots: { customRender: 'spaceCol' }
        },
        // {
        //   class: 'inner-depot-td',
        //   dataIndex: 'stockInTime',
        //   align: 'center',
        //   customRender: (val, row) => {
        //     return {
        //       children: <span>{moment(row.stockInTime).format('YYYY-MM-DD H:mm:ss')}</span>
        //   }
        //   }
        // },
        {
          class: 'inner-depot-td',
          dataIndex: 'productDepotName',
          align: 'center'
        },
        {
          class: 'inner-barcode-td',
          dataIndex: 'barcode',
          align: 'center'
        },
        {
          class: 'inner-empty-td',
          align: 'center'
        },
        // {
        //   class: 'inner-product-name-td',
        //   dataIndex: 'name',
        //   align: 'center'
        // },
        {
          class: 'inner-amount-td',
          dataIndex: 'amount',
          align: 'center',
          scopedSlots: { customRender: 'amount' }
        },
        {
          class: 'inner-unit-td',
          dataIndex: 'unitAmount',
          align: 'center',
          scopedSlots: { customRender: 'unitAmount' }
        },
        // {
        //   class: 'inner-list-price-td',
        //   dataIndex: 'listPrice',
        //   align: 'center',
        //   customRender: (val, row) => {
        //     return {
        //       children: <div>${formatPrice(parseInt(row.price))}</div>
        //     }
        //   }
        // },
        {
          class: 'inner-action-td',
          dataIndex: '',
          align: 'center',
          scopedSlots: { customRender: 'action' }
        }
      ],
      innerTableExpanded: false,
      addSearchValue: '',
      addInventoryProductId: '',
      addInventoryProductName: '',
      addInventoryProductUnit: '',
      pageSizeOptions: ['10', '30', '50', '100'],
      current: 1,
      pageSize: 10,
      total: 10,
      alertMsgTitle: '',
      alertMessage: '',
      form: this.$form.createForm(this, { name: 'dynamic_rule' }),
      rules: {
        searchBarcode: [{ required: true, message: '請輸入商品條碼' }],
        amount: [{ required: false, message: '請輸入數字', pattern: /^\d+$/ }],
        depotId: [{ required: true, message: '請選擇倉庫' }],
        orderId: [{ required: true, message: '請選擇入料單號' }],
      },
      depotName: '',
      depotId: '',
      materialState: {},
    }
  },
  computed: {
    filterName() {
      return this.selectList.map(item => {
        return { value: item.productId, text: item.productName }
      })
    },
    Quantity() {
      return (val, row, key) => {
        let editKey =
          'isEdit' + key[0].toUpperCase() + key.substring(1, key.length)
        return {
          children: (
            <div class="displayInput">
              {row[editKey] ? (
                <div>
                  <a-input
                    autoFocus
                    placeholder="請輸入"
                    value={row[key]}
                    vModel={row[key]}
                    onKeyup={() => (row[key] = row[key].replace(/[^\d]/g, ''))}
                    vOn:Keyup_enter={() => this.addNewItem(row, editKey)}
                  />
                </div>
              ) : (
                <Fragment>
                  <span onClick={() => this.inputORnot(row, editKey)}>
                    {val}
                  </span>
                  <div className="displayEdit" />
                  <a-icon
                    className="editable-cell-icon"
                    type="edit"
                    onClick={() => this.inputORnot(row, editKey)}
                  />
                </Fragment>
              )}
            </div>
          )
        }
      }
    },
    // addSearch() {
    //   return debounce(this.addSelect)
    // },
    computedWeight() {
      return computedWeight
    }
    // filterDepotName(){
    //   if(!this.depotName){
    //     return this.tableData
    //   }else {
    //     return this.tableData.filter(item=> item.depotId.includes(this.depotName))
    //   }
    // }
  },
  watch: {
    searchBarcode: function(newValue) {
      if (newValue == '') {
        this.addInventoryProductId = ''
        this.addInventoryProductName = ''
        this.addInventoryProductUnit = ''
      }
    }
  },
  methods: {
    showMaterialListTableModal() {
      this.$refs.materialListTableModal.show()
    },
    handleSelectMaterial(data) {
      this.materialState = data
      this.list.orderId = data.id
      this.list.materialNumber = data.number
    },
    handleAddMaterial(data) {
      const { number, orderId, ...materialState } = data
      this.materialState = materialState
      this.list.orderId = orderId
      this.list.materialNumber = number
    },
    showAddMaterialModal() {
      this.$refs.addMaterialFormModal.show()
    },
    openNotificationWithIcon(type) {
      this.$notification[type]({
        message: this.alertMsgTitle,
        description: this.alertMessage
      })
    },
    handleExpand(index) {
      if (this.expandIndex.length > 0) {
        let id = this.expandIndex.indexOf(index)
        if (id > -1) {
          this.expandIndex.splice(id, 1)
        } else {
          this.expandIndex.splice(0, this.expandIndex.length)
          this.expandIndex.push(index)
        }
      } else {
        this.expandIndex.push(index)
      }
    },
    getInventoryList(productName) {
      if (this.expandIndex.length) {
        this.handleExpand(this.expandIndex[0])
      }
      this.tableData = []
      this.$api.Inventory.getList(
        productName,
        this.depotId,
        this.current,
        this.pageSize
      )
        .then(res => {
          this.tableData = res.data.inventoryListResponseList.map(
            (item, index) => {
              return {
                ...item,
                id: index,
                totalListPrice: item.price * item.amount,
                unit: computedWeight(undefined, item.unit)
              }
            }
          )
          this.total = res.data.totalElements
        })
        .catch(err => {
          console.log(err)
        })
    },
    getDepotList() {
      this.$api.Depot.getDepotList({
        depotName: this.depotName
      }).then(res => {
        this.goodsTable = res.data
      })
    },
    getDepotId(id) {
      this.current = 1
      this.depotId = id
      this.getInventoryList(this.search)
    },
    showAddPurchaseView() {
      this.purchaseViewVisible = true
      this.clearPurchaseViewData()
      this.$refs.ruleForm.resetFields()
      setTimeout(() => {
        this.$refs.inputDom.focus()
      }, 10)
      setTimeout(() => {
        document
          .querySelector('.addPurchaseView')
          .addEventListener('keyup', this.addInventory)
      }, 10)
    },
    onSearch() {
      this.current = 1
      this.getInventoryList(this.search)
    },
    onCellChange(id, dataIndex, value) {
      this.tableData[id].amount = value
    },
    onInnerCellChange(record, value) {
      const data = {}
      data.id = record.inventoryId
      data.barcode = record.barcode
      data.amount = value
      this.$api.Inventory.edit(data)
        .then(() => {
          this.getInventoryList(this.search)
          this.$message.success('庫存量已變更!')
        })
        .catch(err => {
          console.log(err)
        })
    },
    onDelAmount(record) {
      this.$api.Inventory.deleteInventory(record.inventoryId)
        .then(res => {
          console.log(res)
          this.getInventoryList(this.search)
        })
        .catch(err => {
          this.alertMsgTitle = '錯誤'
          this.alertMessage = err.response.data.message
          this.openNotificationWithIcon('error')
        })
    },
    onHidePurchaseModal() {
      this.clearPurchaseViewData()
      this.purchaseViewVisible = false
    },
    clearPurchaseViewData() {
      this.purchaseViewProductCategories = null
      this.list.searchBarcode = ''
      this.list.amount = ''
      this.list.depotId = undefined
      this.list.orderId = undefined
      this.list.materialNumber = undefined
      this.inventoryList.inventoryId = ''
      this.inventoryList.productName = ''
      this.inventoryList.unit = ''
      this.inventoryList.weight = ''
      this.materialState = {}
    },
    submitNonStop() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          this.submitNonStopLoading = true
          const data = {
            id: this.inventoryList.inventoryId,
            barcode: this.list.searchBarcode,
            depotId: this.purchaseViewProductCategories === 'RETAIL' ? this.list.depotId : null,
            orderId: this.purchaseViewProductCategories === 'RETAIL' ? this.list.orderId : null,
          }
          if (['公斤','公克','台斤'].includes(this.inventoryList.unit)) {
            data.amount = this.inventoryList.amount + 1
            this.$api.Inventory.edit(data)
              .then(() => {
                this.submitNonStopLoading = false
                this.clearPurchaseViewData()
                this.getInventoryList(this.search)
                this.$message.success('入庫成功')
              })
              .catch(err => {
                this.submitNonStopLoading = false
                console.log(err)
              })
          } else {
            data.amount = parseInt(this.list.amount) + this.inventoryList.amount
            this.$api.Inventory.edit(data)
              .then(() => {
                this.submitNonStopLoading = false
                this.clearPurchaseViewData()
                this.getInventoryList(this.search)
                this.$message.success('入庫成功')
              })
              .catch(err => {
                this.submitNonStopLoading = false
                console.log(err)
              })
          }
          setTimeout(() => {
            this.$refs.inputDom.focus()
          }, 10)
        }
      })
    },
    addInventory(e) {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          if (e.target.innerText === '儲 存' || e.key === 'Enter') {
            const isRetail = this.purchaseViewProductCategories === 'RETAIL'
            this.submitLoading = true
            const data = {
              id: this.inventoryList.inventoryId,
              barcode: this.list.searchBarcode,
              depotId: isRetail ? this.list.depotId : null,
              orderId: isRetail ? this.list.orderId : null,
              amount: isRetail ? Number(this.list.amount) : this.inventoryList.amount + 1
            }
            this.$api.Inventory.edit(data)
              .then(() => {
                this.submitLoading = false
                this.purchaseViewVisible = false
                this.clearPurchaseViewData()
                this.getInventoryList(this.search)
                this.$message.success('入庫成功')
              })
              .catch(err => {
                this.submitLoading = false
                console.log(err)
              })
          }
        }
      })
    },
    onShowSizeChange(current, pageSize) {
      this.current = 1
      this.pageSize = pageSize
      this.getInventoryList(this.search)
    },
    onPageChange(current) {
      this.getInventoryList(this.search)
    },
    getCustomerList() {
      this.$api.Inventory.onlyCustomerList().then(res => {
        this.customerList = res.data
      })
    },
    getStock() {
      this.$api.Inventory.getStockDetail({
        barcode: this.list.searchBarcode
      }).then(res => {
        this.inventoryList = res.data
        this.purchaseViewProductCategories = res.data.productCategories
        if (res.data.productCategories === 'RETAIL' && this.purchaseViewDepotList.length === 0) {
          this.$api.Depot.getDepotList({depotName: ''}).then(res => {
            this.purchaseViewDepotList = res.data
          })
        }
        if (['公斤','公克','台斤'].includes(this.inventoryList.unit)) {
          this.rules.amount[0].required = false
          this.list.amount = 1
        } else {
          this.rules.amount[0].required = true
        }
      }).catch(() => {
        this.purchaseViewProductCategories = null
      })
    },
    SalesProduct() {
      this.$api.Commodity.getSalesProduct({
        searchKey: '',
        barcode: '',
        clientId: this.specificId
      }).then(res => {
        this.selectList = [].concat.apply([], res.data)
      })
    },
    resetPage() {
      this.getInventoryList(this.search)
    },
    moment
  },
  created() {
    this.getInventoryList(this.search)
    this.getCustomerList()
    this.getDepotList()
  }
}
</script>
<style scoped lang="scss">
::v-deep .ant-modal {
  top: 40px;
}
.reviewButton {
  background-color: #1890ff;
  color: #fcfcfc;
  font-weight: bold;
  font-size: large;
  border: unset;
}

.addButton1 {
  background-color: #57d698;
  color: #fcfcfc;
  font-weight: bold;
  font-size: large;
  border: unset;
}

.innerTable {
  border-right: 1px solid #e8e8e8;
}
.innerTable::v-deep table {
  border: none;
}
.inventoryTable::v-deep .ant-table-expanded-row td {
  border-right: none;
  border-left: none;
}
.firstPart {
  background-color: #f5f5f5;
  padding: 30px 20px 30px 20px;
}
.firstPart-item {
  display: flex;
}
.firstPart-item > div {
  flex: 1;
}
.second-part {
  padding: 20px 20px 20px 20px;
  /*background-color: #f5e9e9;*/
  .editable-add-btn {
    margin-bottom: 15px;
    .discount {
      display: flex;
      align-items: center;
    }
    .discount > button {
      margin-left: 5px;
      float: left;
    }
    .discount-menu {
      margin-top: 5px;
      flex-direction: column;
    }
  }
}
.inventoryTable::v-deep .ant-table-row-expand-icon-cell,
.inventoryTable::v-deep .ant-table-expand-icon-th {
  width: 0;
  border-right: 0 !important;
  display: none;
}

.inventoryTable::v-deep .ant-table-expanded-row > td:first-child {
  width: 0;
  border-right: 0 !important;
  display: none;
}
.pagination {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}
.editable-cell-text-wrapper {
  padding: 5px 24px 5px 5px;
}

.editable-cell:hover .editable-cell-icon {
  display: inline-block;
}
.discountTable::v-deep .ant-table-row td {
  position: relative;
}
.displayInput {
  display: flex;
  line-height: 15px;
  justify-content: center;
}
.displayEdit {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
}
.displayEdit:hover + .editable-cell-icon {
  display: block;
}
.editable-cell-icon {
  display: none;
  position: relative;
  z-index: 1;
  cursor: pointer;
  top: 3.5px;
}

.editable-cell-icon:hover {
  display: block;
}

::v-deep .expand-td {
  width: 3%;
}
::v-deep .product-name-td {
  width: 20%;
}
::v-deep .product-categories-td {
  width: 8%;
}
::v-deep .unit-td {
  width: 8%;
}
::v-deep .list-price-td {
  width: 10%;
}
::v-deep .amount-td {
  width: 5%;
}
::v-deep .weight-td {
  width: 5%;
}
::v-deep .action-td {
  width: 5%;
}
::v-deep .inner-expand-td {
  width: 5.3%;
}
::v-deep .inner-depot-td {
  width: 36%;
}
::v-deep .inner-barcode-td {
  width: 15%;
}
::v-deep .inner-empty-td {
  width: 10%;
}
::v-deep .inner-amount-td {
  width: 9%;
}
::v-deep .inner-unit-td {
  width: 9%;
}
::v-deep .inner-action-td {
  width: 9%;
}
.expandAction {
  position: relative;
  cursor: pointer;
}
.expandAction::before {
  content: '';
  position: absolute;
  top: -20px;
  right: -20px;
  bottom: -20px;
  left: -20px;
}
.barCodeExpand {
  position: relative;
}
.barCodeExpand::before {
  content: '';
  position: absolute;
  top: -20px;
  right: -20px;
  bottom: -20px;
  left: -20px;
}

.purchase-form {
  display: flex;
  width: 752px;
  &__row {
    flex: 1;
    &__item {
      width: 100%;
      display: flex;
      &::v-deep {
        .ant-form-item-label {
          label:not(.ant-form-item-required) {
            padding-left: 11px;
          }
        }
      }
    }
    &::v-deep .ant-form-item-control-wrapper {
      flex: 1;
    }
  }
}

.addPurchaseView {
  &::v-deep .ant-form-inline .ant-form-item-with-help {
    margin-bottom: 0;
  }
}
</style>

<template>
  <a-modal
    title="新增入料"
    :visible="visible"
    width="800px"
    :confirm-loading="confirmLoading"
    @cancel="handleCancel"
    @ok="handleOk"
  >
    <a-form-model :model="data" ref="ruleForm" :rules="rules">
      <a-form-model-item
        label="入料單號"
        prop="number"
      >
        <a-input
          v-model="data.number"
          disabled
        />
      </a-form-model-item>
      <a-form-model-item
        label="物料來源"
        prop="materialId"
      >
        <a-select
          placeholder="請選擇物料來源"
          v-model="data.materialId"
        >
          <a-select-option v-for="e in names" v-model="e.id" :key="e.id">
            {{ e.name }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item
        label="物料數量"
        prop="count"
      >
        <a-input
          placeholder="請輸入物料數量(空為1)"
          v-model="data.count"
          type="number"
        />
      </a-form-model-item>
      <a-form-model-item
        label="屠體重量(公斤)"
        prop="carcassWeight"
      >
        <a-input
          placeholder="請輸入屠體重量(公斤)(空為0)"
          v-model="data.carcassWeight"
          type="number"
        />
      </a-form-model-item>
      <a-form-model-item
        label="毛雞重量(公斤)"
        prop="livingWeight"
      >
        <a-input
          placeholder="請輸入毛雞重量(公斤)(空為0)"
          v-model="data.livingWeight"
          type="number"
        />
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>

<script>
const initialData = {
  number: undefined,
  materialId: undefined,
  count: undefined,
  carcassWeight: undefined,
  livingWeight: undefined,
}

export default {
  name: 'add-material-form-modal',
  data(){
    return {
      data: { ...initialData },
      rules: {
        materialId: [{ required: true, message: '請選擇物料來源' }],
      },
      names: [],
      visible: false,
      confirmLoading: false,
    }
  },
  methods: {
    show() {
      this.visible = true
      this.data = { ...initialData }

      this.$api.Inventory.getMaterialList().then(res => {
        this.names = res.data
      }).catch(() => {})

      this.$api.Inventory.getMaterialDepotOrderNumber().then(res => {
        this.data.number = res.data
      }).catch(() => {})
    },
    handleCancel(){
      this.visible = false
    },
    async handleOk(){
      const {data} = this
      const {carcassWeight, count, livingWeight, materialId} = data
      this.confirmLoading = true
      const transInt = (e, defaultValue = 0) => typeof e === 'number' ? e : typeof e === 'string' ? Number(e) : defaultValue
      const request = {
        carcassWeight: transInt(carcassWeight),
        count: transInt(count, 1),
        livingWeight: transInt(livingWeight),
        materialId,
        username: localStorage.getItem('erp-user'),
      }
      this.$api.Inventory.addMaterialDepotOrder(request).then((res) => {
        this.visible = false
        this.confirmLoading = false
        this.$emit('ok', { number: this.data.number, orderId: res.data, name: this.names.find(e => e.id === materialId)?.name, ...request })
      }).catch(() => { this.confirmLoading = false })
    },
  }
}
</script>

<style scoped>

</style>
